<template>
  <div>
    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
          <div>
            <h2 class="font-weight-bolder m-0" >
              <span @click="$router.go(-1)" >
          <b-button
              variant="white"
              class="btn-icon rounded-circle mr-50 text-danger bg-white"
              size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span> Partes de trabajo de obras
            </h2>
            <h4 class="pl-3 pt-1"> {{project.name}} </h4>
          </div>
        </div>
      </b-col>
      <b-col cols="12" >
        <b-card no-body >
          <div class="d-flex align-items-center justify-content-between p-2" >
            <h5 class="m-0" >TAREAS DE ESTA OBRA</h5>
          </div>

          <b-table
              hover
              responsive
              :items="items"
              :fields="columns"
              class="table-white"
          >
            <template #cell(date_ini)="data">
              {{ TimestampToFlatPickr(data.item.date_ini) }}
            </template>
            <template #cell(date_fin)="data">
              {{ TimestampToFlatPickr(data.item.date_fin) }}
            </template>
            <template #cell(status)="data">
              <b-badge
                  :variant="statusTasksVariant[data.item.status]"
              >
                {{ statusTasksText[data.item.status] }}
              </b-badge>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                    :to="{ name: 'partesObrasJourneys', params: { id: data.item.id} }"
                    class="d-inline-block px-50 text-dark"
                >
                  <feather-icon icon="EyeIcon" />
                </b-link>
                  <a
                      v-if="(data.item.pdf)"
                      :href="data.item.pdf"
                      target="_blank"
                      download
                      class="d-inline-block px-50 text-dark"
                  >
                    <feather-icon icon="DownloadIcon" />
                  </a>
              </span>
            </template>
          </b-table>
          <div class="d-flex align-items-center justify-content-between mb-1 px-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap ">
              {{ 'Por página' }}:
            </span>
            <b-form-select
                v-model="pageLength"
                :options="pages"
                class="ml-50 mr-1"
                @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ 'Total' }}: {{ totalItems }}</span>
          </div>
          <div>
            <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BCol,
  BRow, BLink, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import store from '@/store/index'
import { FlatPickrToTimestamp, TimestampToFlatPickr } from '@/libs/helpers'
import {config} from "@/shared/app.config";

export default {
  components: {
    BBadge,
    BAvatar,
    BCard,
    BTable,
    BButton,
    BPagination,
    BCol,
    BRow,
    BLink,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupPrepend,
  },
  data() {
    return {
      statusTasksVariant: config.statusTasksVariants,
      statusTasksText: config.statusTasksText,
      log: [],
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ['10', '15', '25'],
      columns: [
        {
          label: 'Nombre',
          key: 'name',
        },
        {
          label: 'Inicio',
          key: 'date_ini',
        },
        {
          label: 'Fin',
          key: 'date_fin',
        },
        {
          label: 'Estado',
          key: 'status',
        },
        {
          label: 'HORAS IMPUTADAS',
          key: 'total_hours',
        },
        {
          label: 'Acciones',
          key: 'actions',
          class: 'column-actions',
        },
      ],
      searchTerm: '',
    }
  },
  computed: {
    ...mapGetters({
      project: 'projects/getProject',
      items: 'tasks/getItems',
      totalItems: 'tasks/getTotalItems',
    }),
  },
  async created() {
    await this.getProject(this.$route.params.id)
    await this.chargeData()
  },
  methods: {
    ...mapActions({
      getProject: 'projects/getProject',
      list: 'tasks/getListTasks',
    }),
    TimestampToFlatPickr(f) {
      return TimestampToFlatPickr(f)
    },
    chargeData() {
      this.list({
        client: '',
        page: this.currentPage,
        per_page: this.pageLength,
        search: '',
        date_ini: '',
        date_fin: '',
        status: '',
        orderby: '',
        project: this.$route.params.id,
      })
    },
    handleSearch() {
      this.currentPage = 1
      this.chargeData()
    },
    handleChangePage(page) {
      this.currentPage = page
      this.chargeData()
    },
    handlePageChange() {
      this.currentPage = 1
      this.chargeData()
    },
    onSortChange(params) {
      this.currentPage = 1
      this.chargeData()
    },
  },
}
</script>
